.animated-toaster {
  animation: slideIn 0.5s forwards; /* Apply the slide-in animation */
}

@keyframes slideIn {
  from {
    right: -100%; /* Start off-screen */
  }
  to {
    right: 1%; /* Slide in to the desired position */
  }
}
